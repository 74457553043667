import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogContent, MatDialogModule, MatDialogRef, MatDialogTitle } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { distinctUntilChanged, takeUntil } from 'rxjs';
import { adgnbNegativeValidator } from '../../../../../../../util/validation.helper';
import { BaseComponent } from '../../../../../../core/components/base/base.component';
import { CommaDecimalInputComponent } from '../../../../../../core/components/decimal-comma/decimal-comma.component';
import { AnalyticsService } from '../../../../../../core/services/analytics/analytics.service';
import { ProjectService } from '../../../../../../core/services/project.service';
import { MixedUsageProfileFloorGroup, Tooltip } from '../../../project-info-page/project-info-page.component';

export interface FloorParametersDialogComponentConfig {
  form: FormGroup | null;
  editMode: boolean | null;
}
export interface FloorParametersDialogComponentResult {
  form: FormGroup | null;
  editMode: boolean | null;
}

@Component({
  selector: 'eule-floor-parameters-dialog',
  standalone: true,
  imports: [
    CommaDecimalInputComponent,
    CommonModule,
    MatButtonModule,
    MatDatepickerModule,
    MatDialogActions,
    MatDialogContent,
    MatDialogModule,
    MatDialogTitle,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatTooltipModule,
    ReactiveFormsModule,
  ],
  templateUrl: './floor-parameters-dialog.component.html',
  styleUrl: './floor-parameters-dialog.component.scss',
})
export class FloorParametersDialogComponent extends BaseComponent {
  public floorForm: FormGroup<MixedUsageProfileFloorGroup> = this._formBuilder.group<MixedUsageProfileFloorGroup>({
    name: this._formBuilder.control<string | null>(null),
    bgf: this._formBuilder.control<number | null>({ value: null, disabled: true }), // bgf is combined Value of kgf + nrf
    bri: this._formBuilder.control<number | null>(null),
    nrf: this._formBuilder.control<number | null>({ value: null, disabled: true }), // nrf is combined Value of nuf + tf + vf
    nuf: this._formBuilder.control<number | null>(null, [adgnbNegativeValidator()]), // , Validators.pattern(germanNumberExp)
    tf: this._formBuilder.control<number | null>(null),
    vf: this._formBuilder.control<number | null>(null),
    kgf: this._formBuilder.control<number | null>(null),
    adgnb: this._formBuilder.control<number | null>({ value: null, disabled: true }), // adgnb is combined Value of nuf - nfVehicle + vfHallCorridor
    nfVehicle: this._formBuilder.control<number | null>(null, [adgnbNegativeValidator()]), //, Validators.pattern(germanNumberExp)
    vfHallCorridor: this._formBuilder.control<number | null>(null, [adgnbNegativeValidator()]), // Validators.pattern(germanNumberExp)
  });

  public constructor(
    private _analyticsService: AnalyticsService,
    @Inject(MAT_DIALOG_DATA) public data: FloorParametersDialogComponentConfig | null,
    public projectService: ProjectService,
    private _formBuilder: FormBuilder,
    private _dialogRef: MatDialogRef<FloorParametersDialogComponent>
  ) {
    super();
    if (this.data?.form) {
      this.floorForm = this.data!.form;
    }
    this._setListenerAndCalculateCombinedValues();
  }

  public closeDialog(save: boolean) {
    if (save) {
      this._analyticsService.sendEvent('button_click', {
        label: 'floor-parameters-dialog_button-save',
      });
      this._dialogRef.close({ form: this.floorForm, editMode: this.data?.editMode });
    } else {
      this._dialogRef.close(null);
    }
  }

  public tooltips: Tooltip = {
    hasMixedUsageProfiles: `Mischnutzung
    Befinden sich im betrachteten Gebäude mehrere unterschiedliche Nutzungen, muss überprüft werden, welches Nutzungsprofil anzuwenden ist. Grundlage für die Einschätzung, ob die Zertifizierung nach MIX23 stattfinden muss, bildet die DGNB Bemessungsfläche.
    Es wird empfohlen die Einstufungen unterschiedlicher Nutzungen mit der DGNB Geschäftsstelle abzustimmen.
    Weiterführende Informationen finden Sie in dem Dokument Anwendungsregeln zur Mischnutzung, Version 23 (MIX23) von der DGNB.`,
    sideUsage: `Eine oder mehrere Nutzungen, die einem anderen Nutzungsprofil als der Hauptnutzung zugeordnet werden und deren Flächenanteil an der gesamten DGNB Bemessungsfläche ≥ 15% beträgt, wird als Nebennutzung bezeichnet. Die Flächen einer Nebennutzung müssen mit dem entsprechenden Nutzungsprofil bewertet werden. `,
    mainUsage: `Das Nutzungsprofil mit dem größten Flächenanteil an der gesamten DGNB Bemessungsfläche wird als Hauptnutzung bezeichnet. Ist die Einstufung nicht eindeutig möglich, ist die Hauptnutzung festzulegen und die Entscheidung zu begründen.`,
    flaechenberechnungNachDin277: `Flächenberechnung nach DIN 277
    BGF(R) = KGF(R) + NRF(R)    
    NRF(R) = NUF(R) + TF(R) + VF(R)
    BGF                Brutto-Grundfläche
    KGF                Konstruktions-Grundfläche
    NRF                Netto-Raumfläche
    NUF                Nutzungsfläche
    TF                 Technikfläche
    VF                 Verkehrsfläche`,
    bemessungsflaeche: `DGNB Bemessungsfläche
    ADGNB = NUFa - NUF a,7,4 + VFa,9,1
    ADGNB                 DGNB Bemesungsfläche
    NUFa                  Nutzungsfläche nach DIN277
    NUF a,7,4            Fahrzeugabstellfläche nach DIN 277
    VFa,9,1                Verkehrsfläche Flure und Hallen nach DIN277
    Weiterführende Informationen finden Sie in dem Dokument Anwendungsregeln zur Mischnutzung, Version 23 (MIX23) von der DGNB.`,
  };

  private _setListenerAndCalculateCombinedValues() {
    ///////////////////////// set bgf
    this.floorForm
      .get('nrf')!
      .valueChanges.pipe(distinctUntilChanged(), takeUntil(this.stop$))
      .subscribe(() => {
        const _sum: number = Number(this.floorForm.get('nrf')!.value) + Number(this.floorForm.get('kgf')!.value);
        const _roundedSum: number = Math.round(_sum * 100) / 100;
        this.floorForm.get('bgf')!.setValue(_roundedSum);
      });
    this.floorForm
      .get('kgf')!
      .valueChanges.pipe(distinctUntilChanged(), takeUntil(this.stop$))
      .subscribe(() => {
        const _sum: number = Number(this.floorForm.get('nrf')!.value) + Number(this.floorForm.get('kgf')!.value);
        const _roundedSum: number = Math.round(_sum * 100) / 100;
        this.floorForm.get('bgf')!.setValue(_roundedSum);
      });
    ///////////////////////// set nrf
    this.floorForm
      .get('tf')!
      .valueChanges.pipe(distinctUntilChanged(), takeUntil(this.stop$))
      .subscribe(() => {
        const _sum: number = Number(this.floorForm.get('nuf')!.value) + Number(this.floorForm.get('tf')!.value) + Number(this.floorForm.get('vf')!.value);
        const _roundedSum: number = Math.round(_sum * 100) / 100;
        this.floorForm.get('nrf')!.setValue(_roundedSum);
      });
    this.floorForm
      .get('vf')!
      .valueChanges.pipe(distinctUntilChanged(), takeUntil(this.stop$))
      .subscribe(() => {
        const _sum: number = Number(this.floorForm.get('nuf')!.value) + Number(this.floorForm.get('tf')!.value) + Number(this.floorForm.get('vf')!.value);
        const _roundedSum: number = Math.round(_sum * 100) / 100;
        this.floorForm.get('nrf')!.setValue(_roundedSum);
      });
    ///////////////////////// set adgnb
    this.floorForm
      .get('nuf')!
      .valueChanges.pipe(distinctUntilChanged(), takeUntil(this.stop$))
      .subscribe(() => {
        const _sumNrf: number = Number(this.floorForm.get('nuf')!.value) + Number(this.floorForm.get('tf')!.value) + Number(this.floorForm.get('vf')!.value);
        const _roundedSumNrf: number = Math.round(_sumNrf * 100) / 100;
        this.floorForm.get('nrf')!.setValue(_roundedSumNrf);
        const _sumAdgnb: number =
          Number(this.floorForm.get('nuf')!.value) - Number(this.floorForm.get('nfVehicle')!.value) + Number(this.floorForm.get('vfHallCorridor')!.value);
        const _roundedSumAdgnb: number = Math.round(_sumAdgnb * 100) / 100;
        this.floorForm.get('adgnb')!.setValue(_roundedSumAdgnb);
        this.floorForm.get('nuf')?.updateValueAndValidity();
      });
    this.floorForm
      .get('nfVehicle')!
      .valueChanges.pipe(distinctUntilChanged(), takeUntil(this.stop$))
      .subscribe(() => {
        const _sum: number =
          Number(this.floorForm.get('nuf')!.value) - Number(this.floorForm.get('nfVehicle')!.value) + Number(this.floorForm.get('vfHallCorridor')!.value);
        const _roundedSum: number = Math.round(_sum * 100) / 100;
        this.floorForm.get('adgnb')!.setValue(_roundedSum);
        this.floorForm.get('nfVehicle')?.updateValueAndValidity();
      });
    this.floorForm
      .get('vfHallCorridor')!
      .valueChanges.pipe(distinctUntilChanged(), takeUntil(this.stop$))
      .subscribe(() => {
        const _sum: number =
          Number(this.floorForm.get('nuf')!.value) - Number(this.floorForm.get('nfVehicle')!.value) + Number(this.floorForm.get('vfHallCorridor')!.value);
        const _roundedSum: number = Math.round(_sum * 100) / 100;
        this.floorForm.get('adgnb')!.setValue(_roundedSum);
        this.floorForm.get('vfHallCorridor')?.updateValueAndValidity();
      });
  }
}
