import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CommaDecimalInputComponent } from '../../../../core/components/decimal-comma/decimal-comma.component';
import { AnalyticsService } from '../../../../core/services/analytics/analytics.service';
import { ProjectService } from '../../../../core/services/project.service';

@Component({
  selector: 'eule-tab-contact-persons',
  standalone: true,
  imports: [
    CommonModule,
    CommaDecimalInputComponent,
    MatButtonModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    MatSlideToggleModule,
    ReactiveFormsModule,
    MatTooltipModule,
  ],
  templateUrl: './tab-contact-persons.component.html',
  styleUrl: './tab-contact-persons.component.scss',
})
export class TabContactPersonsComponent {
  @Input({ required: true }) contactPersonsFormGroup!: FormGroup;

  public constructor(private _analyticsService: AnalyticsService, public projectService: ProjectService) {
    this._analyticsService.sendPageView('tab-contact-persons-component');
  }
}
