<div id="container">
  <div class="column user-info flexbox direction-column justify-center xs-gap">
    <div class="user-name primary">{{ getUserName() }}</div>
    @if ((userService.euleUser$ | async)?.title; as userTitle) {
    <div class="user-title">{{ userTitle }}</div>
    }
  </div>

  <eule-profile-picture [matMenuTriggerFor]="beforeMenu" (click)="clickProfilePicture()"></eule-profile-picture>

  <mat-menu #beforeMenu="matMenu" xPosition="before">
    <div class="flexbox direction-column align-center justify-center">
      <button mat-menu-item (click)="navigate('profile')" data-cy="button-profile-menu-profile">
        <mat-icon>person</mat-icon>
        <span>Mein Profil</span>
      </button>
      <!-- Disabled, coming later -->
      <!-- <button mat-menu-item (click)="navigate('subscription')">Abonnement</button>
      <button mat-menu-item (click)="navigate('mobile')">Mobile App</button>
      <button mat-menu-item (click)="navigate('help')">Hilfe</button> -->
      <button mat-menu-item (click)="openContactPageInNewTab()" data-cy="button-profile-menu-contact">
        <mat-icon>contact_support</mat-icon>
        <span>Kontakt</span>
      </button>
      <div class="separator secondary-border-color-alt"></div>
      <div mat-menu-item>
        <mat-icon>bedtime</mat-icon>
        <mat-slide-toggle [formControl]="toggleControl" class="mr-8" data-cy="toggle-profile-menu-darkmode"></mat-slide-toggle>
      </div>
      <button mat-menu-item (click)="logout()" data-cy="button-profile-menu-logout">
        <mat-icon>logout</mat-icon>
        <span>Logout</span>
      </button>
    </div>
  </mat-menu>
</div>
