import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { UserService } from '../../../core/services/user.service';
import { AnalyticsService } from '../../../core/services/analytics/analytics.service';

interface BugReportForm {
  email: FormControl<string | null>;
  creatorId: FormControl<string | null>;
  description: FormControl<string | null>;
  expectedBehaviour: FormControl<string | null>;
  actualBehaviour: FormControl<string | null>;
  date: FormControl<number | null>;
}

export interface BugReportDialogComponentConfig {}
export interface BugReportDialogComponentResult {
  id?: string;
  email: string;
  description: string;
  expectedBehaviour: string;
  actualBehaviour: string;
  creatorId: string;
  date: number;
}

@Component({
  selector: 'eule-bug-report-dialog',
  standalone: true,
  imports: [CommonModule, FormsModule, MatButtonModule, MatDialogModule, MatFormFieldModule, MatIconModule, MatInputModule, ReactiveFormsModule],
  templateUrl: './bug-report-dialog.component.html',
  styleUrl: './bug-report-dialog.component.scss',
})
export class BugReportDialogComponent {
  public constructor(
    private _analyticsService: AnalyticsService,
    private _dialogRef: MatDialogRef<BugReportDialogComponent>,
    private _formBuilder: FormBuilder,
    private _userService: UserService
  ) {
    this._analyticsService.sendPageView('Bug-Report-Dialog');
  }

  public bugReportForm: FormGroup<BugReportForm> = this._formBuilder.group({
    email: this._formBuilder.control(this._userService.euleUser$.value!.email, [Validators.required]),
    creatorId: this._formBuilder.control(this._userService.euleUser$.value!.id, [Validators.required]),
    description: this._formBuilder.control('', Validators.required),
    expectedBehaviour: this._formBuilder.control('', Validators.required),
    actualBehaviour: this._formBuilder.control('', Validators.required),
    date: this._formBuilder.control(new Date().valueOf()),
  });

  public closeDialog(save: boolean) {
    if (save) {
      this._analyticsService.sendEvent('button_click', { label: 'bug-report-dialog_button-save' });
      this._dialogRef.close(this.bugReportForm.getRawValue());
    } else {
      this._analyticsService.sendEvent('button_click', { label: 'bug-report-dialog_button-cancel' });
      this._dialogRef.close(null);
    }
  }
}
