import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DocumentData, Project } from '@eeule/eeule-shared/src/types';
import { EMPTY, Observable, Subscription, catchError, forkJoin, lastValueFrom } from 'rxjs';
import { handleBasicError } from '../../../../../util/error.helper';
import { ProjectService } from '../../../../core/services/project.service';
import { SnackbarService } from '../../../../core/services/snackbar.service';
import { StorageService } from '../../../../core/services/storage.service';
import { UploadDocumentDialogComponent, UploadDocumentDialogConfig } from '../../../components/upload-document-dialog/upload-document-dialog.component';
import { DocumentService } from '../../../../core/services/document.service';

@Injectable({
  providedIn: 'root',
})
export class ConnectAttachmentsService {
  constructor(
    private _projectService: ProjectService,
    private _storageService: StorageService,
    private _dialog: MatDialog,
    private _snackBarService: SnackbarService,
    private _documentService: DocumentService
  ) {}

  public async onOpenAttachment(attachmentId: string) {
    const project: Project | null = this._projectService.project$.value;
    if (!project) {
      return handleBasicError('Error while retrieving current project');
    }
    const downloadUrl: string = await lastValueFrom(this._storageService.getProjectDocumentDownloadUrl(project.id, attachmentId));
    window.open(downloadUrl, '_blank');
  }

  public onNewProjectDescriptionGroupAttachment() {
    const dialogRef = this._dialog.open<UploadDocumentDialogComponent, UploadDocumentDialogConfig, DocumentData>(UploadDocumentDialogComponent, {
      width: '600px',
      maxWidth: '70vw',
      data: null,
    });

    const afterClosedSub: Subscription = dialogRef.afterClosed().subscribe((document: DocumentData | undefined) => {
      if (document) {
        this._storageService
          .uploadProjectDocument(this._projectService.project$.value!.id, document)
          .pipe(
            catchError(error => {
              this._snackBarService.showErrorMessage('Beim Hochladen der Datei ist ein Fehler aufgetreten');
              console.error(error);
              return EMPTY;
            })
          )
          .subscribe(() => {
            afterClosedSub.unsubscribe();
          });
      }
    });
  }

  public onUpdate(allDocuments: DocumentData[], connectedAttachments: DocumentData[], groupId: string): Observable<void[]> {
    if (!connectedAttachments) {
      return EMPTY;
    }
    // All docs With This Groups ID in their projectInfoDescriptionGroupIds
    const allDocsWithThisGroupId = allDocuments.filter(docFromAll => docFromAll.projectInfoDescriptionGroupIds?.includes(groupId));
    // docs With This Groups ID in their projectInfoDescriptionGroupIds, that are NOT included In Connected Attachments (need to remove this Groups ID from their projectInfoDescriptionGroupIds)
    const docsToRemoveThisGroupId = allDocsWithThisGroupId.filter(doc => !connectedAttachments.some(compDoc => compDoc.id === doc.id));
    const docsWithRemovedGroupId = docsToRemoveThisGroupId.map(doc => {
      return { ...doc, projectInfoDescriptionGroupIds: doc.projectInfoDescriptionGroupIds?.filter(element => element !== groupId) };
    });
    const docsToUpdate = [
      ...docsWithRemovedGroupId.map(doc => {
        return { id: doc.id, projectInfoDescriptionGroupIds: Array.from(new Set([...(doc.projectInfoDescriptionGroupIds || [])])) };
      }),
      ...connectedAttachments.map(doc => {
        return { id: doc.id, projectInfoDescriptionGroupIds: Array.from(new Set([...(doc.projectInfoDescriptionGroupIds || []), groupId])) };
      }),
    ];
    return this.performBulkUpdate(docsToUpdate);
  }

  public performBulkUpdate(documentsToUpdate: Array<{ id: string; projectInfoDescriptionGroupIds: string[] }>): Observable<void[]> {
    const project: Project | null = this._projectService.project$.value;
    if (!project?.id) {
      this._snackBarService.showErrorMessage('Beim Aktualisieren der Aufgabendokumente ist ein Fehler aufgetreten. Keine Projekt ID');
      throw new Error('current project was not found, while updating task documents');
    }
    const update$ = documentsToUpdate.map(_document =>
      this._documentService.updateProjectDocument(project.id, _document.id, {
        projectInfoDescriptionGroupIds: _document.projectInfoDescriptionGroupIds,
      })
    );
    return forkJoin(update$);
  }
}
