import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ReactiveFormsModule, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { AuthService } from '../../../core/services/auth.service';
import { AnalyticsService } from '../../../core/services/analytics/analytics.service';

interface NewPasswordForm {
  newPassword: FormControl<string | null>;
  newPasswordRepeat: FormControl<string | null>;
}

export function createPasswordStrengthValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const newPasswordRepeatValue = control.value;
    const newPassword = control.parent?.get('newPassword');

    if (!newPassword) {
      return { passwordMatch: true };
    }
    if (!newPassword.value) {
      return null;
    }
    if (!newPasswordRepeatValue) {
      return null;
    }
    const passwordValid = newPassword.value === newPasswordRepeatValue;

    return !passwordValid ? { passwordMatch: true } : null;
  };
}

export interface NewPasswordDialogComponentConfig {}

@Component({
  selector: 'eule-new-password-dialog',
  standalone: true,
  imports: [CommonModule, MatFormFieldModule, MatInputModule, ReactiveFormsModule, MatButtonModule, MatDialogModule],
  templateUrl: './new-password-dialog.component.html',
  styleUrl: './new-password-dialog.component.scss',
})
export class NewPasswordDialogComponent {
  public newPasswordForm: FormGroup<NewPasswordForm> = this._formBuilder.group({
    newPassword: this._formBuilder.control('', [
      Validators.required,
      Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&+\\-§€])[A-Za-z\\d$@$!%*?&+\\-§€]{8,}$'),
    ]),
    newPasswordRepeat: this._formBuilder.control('', [Validators.required, createPasswordStrengthValidator()]),
  });

  public constructor(
    private _analyticsService: AnalyticsService,
    public dialogRef: MatDialogRef<NewPasswordDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: NewPasswordDialogComponentConfig,
    private _formBuilder: FormBuilder,
    private _authService: AuthService
  ) {
    this._analyticsService.sendPageView('new-password-Dialog');
    this.newPasswordForm.get('newPassword')!.valueChanges.subscribe(() => {
      this.newPasswordForm.get('newPasswordRepeat')!.updateValueAndValidity();
    });
  }

  public save() {
    this._analyticsService.sendEvent('button_click', { label: 'new-password-dialog_button_save' });
    if (this.newPasswordForm.get('newPassword')!.value !== null) {
      this._authService.updateEuleUserPassword(this.newPasswordForm.get('newPassword')!.value!).subscribe(() => this.dialogRef.close(true));
    } else {
      throw new Error('No Password entered');
    }
  }
}
