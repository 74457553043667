import { CommonModule } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { Router } from '@angular/router';
import { Pagination, TaskData } from '@eeule/eeule-shared/src/types/index';
import { Timestamp } from 'firebase/firestore';
import { BehaviorSubject, catchError, forkJoin, map, mergeMap, of, takeUntil } from 'rxjs';
import { timeStampToDate } from '../../../../util/date.helper';
import { getDisciplineEnumValue } from '../../../../util/enum.helper';
import { BaseComponent } from '../../../core/components/base/base.component';
import { GeneralTitleComponent } from '../../../core/components/general-title/general-title.component';
import { SearchBarComponent } from '../../../core/components/search-bar/search-bar.component';
import { TypeSafeMatCellDef } from '../../../core/directives/TypeSafeMatCellDef';
import { AnalyticsService } from '../../../core/services/analytics/analytics.service';
import { ProjectService } from '../../../core/services/project.service';
import { TaskService } from '../../../core/services/task.service';
import { ProjectUserDisplay, UserService } from '../../../core/services/user.service';
import { DisciplineEnum } from '../../../enums/Discipline.enum';
import { PriorityEnum } from '../../../enums/Priority.enum';
import { TaskStatusEnum } from '../../../enums/TaskStatus.enum';
import { TaskTypeEnum } from '../../../enums/TaskType.enum';
import { FirebaseDocumentData } from '../../../types/firebase-types';
import { IndicatorTaskDialogComponent, IndicatorTaskDialogComponentConfig } from '../../components/indicator-task-dialog/indicator-task-dialog.component';

@Component({
  selector: 'eule-audit-tasks-page',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatInputModule,
    FormsModule,
    GeneralTitleComponent,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    SearchBarComponent,
    TypeSafeMatCellDef,
    MatCardModule,
  ],
  templateUrl: './audit-tasks-page.component.html',
  styleUrl: './audit-tasks-page.component.scss',
})
export class AuditTasksPageComponent extends BaseComponent implements AfterViewInit {
  public displayedColumns: string[] = [
    'taskNumber',
    'title',
    'type',
    'creatorId',
    'discipline',
    'responsibleId',
    'lph',
    'priority',
    'status',
    'createTime',
    'updateTime',
  ];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public priorityTypeEnum: typeof PriorityEnum | any = PriorityEnum;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public taskTypeEnum: typeof TaskTypeEnum | any = TaskTypeEnum;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public taskStatusEnum: typeof TaskStatusEnum | any = TaskStatusEnum;
  public disciplineEnum: typeof DisciplineEnum = DisciplineEnum;
  public dataSource: MatTableDataSource<TaskData> = new MatTableDataSource();
  public tasks: TaskData[] = [];
  public isLoading$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  public isInitialized$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public filterValue: string = '';
  public projectId: string | undefined;

  @ViewChild(MatPaginator) paginator: MatPaginator | null = null;
  @ViewChild(MatSort) sort: MatSort | null = null;

  constructor(
    private _analyticsService: AnalyticsService,
    public userService: UserService,
    private _router: Router,
    private _taskService: TaskService,
    private _taskDialog: MatDialog,
    private _projectService: ProjectService,
    private cdr: ChangeDetectorRef
  ) {
    super();
    this._analyticsService.sendPageView('audit-tasks-page');
    //fixme just a workaround, project should be emitted elsewhere at this point
    this.projectId = this._projectService.project$.value?.id;
    if (!this.projectId) {
      const urlProjectPartArr: string[] = this._router.url.split('/');
      const projectIndex: number = urlProjectPartArr.indexOf('project');
      this.projectId = urlProjectPartArr[projectIndex + 1] || '';
    }

    if (!this.projectId) {
      throw new Error('No Project set');
    }

    this._taskService
      .getLiveAllProjectTasks(this.projectId)
      .pipe(
        // read project User Data for displaying Creator in List
        mergeMap((tasks: TaskData[]) => {
          const arr = tasks.map(_task =>
            this._projectService.getProjectUserDisplay(this.projectId as string, _task.creatorId).pipe(
              map((user: ProjectUserDisplay) => {
                return { ..._task, creatorId: `${user?.firstName || ''} ${user?.lastName || ''}` }; // only for display purposes
              })
            )
          );
          if (!arr?.length) {
            this.isLoading$.next(false);
            this.isInitialized$.next(true);
          }
          return forkJoin(arr);
        }),
        // read project User Data for displaying Responsible in List
        mergeMap((tasks: TaskData[]) => {
          const arr = tasks.map(_task => {
            if (_task.responsibleId && this.projectId) {
              return this._projectService.getProjectUserDisplay(this.projectId, _task.responsibleId).pipe(
                map((user: ProjectUserDisplay) => {
                  return { ..._task, responsibleId: `${user?.firstName || ''} ${user?.lastName || ''}` }; // only for display purposes
                })
              );
            } else {
              return of(_task);
            }
          });
          return forkJoin(arr);
        }),
        catchError(error => {
          this.isLoading$.next(false);
          throw new Error(error);
        }),
        takeUntil(this.stop$)
      )
      .subscribe((_tasks: TaskData[]) => {
        this.tasks = _tasks;
        this.dataSource.data = this.tasks.map(task => {
          const _createTime: Timestamp | undefined = (task as FirebaseDocumentData<TaskData>).createTime;
          const _updateTime: Timestamp | undefined = (task as FirebaseDocumentData<TaskData>).updateTime;
          return {
            ...task,
            discipline: getDisciplineEnumValue(task.discipline) as DisciplineEnum,
            createTime: timeStampToDate(_createTime),
            updateTime: timeStampToDate(_updateTime),
          };
        });
        this.isLoading$.next(false);

        if (this.isInitialized$.value) return;

        this.isInitialized$.next(true);
      });
  }

  ngAfterViewInit(): void {
    if (this.paginator) {
      this.dataSource.paginator = this.paginator;
    }
    if (this.sort) {
      this.dataSource.sort = this.sort;
      this.dataSource.sort.sort({ id: 'taskNumber', start: 'asc', disableClear: false });
    }
    this.cdr.detectChanges();
  }

  getDiscipline(row: TaskData): DisciplineEnum | undefined {
    return row.discipline;
  }

  applyFilter(_filterValue: string) {
    this.filterValue = _filterValue;
    this.dataSource.filter = _filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  public apply() {}

  public onNewTask() {
    this._analyticsService.sendEvent('button_click', {
      label: 'audit-tasks-page_new-task',
    });
    this._taskDialog.open<IndicatorTaskDialogComponent, IndicatorTaskDialogComponentConfig, string>(IndicatorTaskDialogComponent, {
      width: '70vw',
      height: '80vh',
      data: { id: null },
    });
  }

  public clickTask(_task: TaskData) {
    this._analyticsService.sendEvent('button_click', {
      label: 'audit-tasks-page_open-task',
    });
    this._taskDialog.open<IndicatorTaskDialogComponent, IndicatorTaskDialogComponentConfig, string>(IndicatorTaskDialogComponent, {
      width: '70vw',
      height: '80vh',
      data: { id: _task.id },
    });
  }

  public onChangePageSize(pageSize: Pagination | number) {
    this.userService.updateUser(this.userService.euleUser$.value!.id, { pagination: pageSize as Pagination });
  }
}
