import { Component, Input } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { MatDividerModule } from '@angular/material/divider';
import { AnalyticsService } from '../../../../core/services/analytics/analytics.service';
import { DescriptionGroupFormGroup } from '../project-info-page/project-info-page.component';
import { UsageProfileGroupComponent } from './usage-profiles-group/usage-profiles-group.component';

@Component({
  selector: 'eule-tab-usage-profiles',
  standalone: true,
  imports: [UsageProfileGroupComponent, MatDividerModule],
  templateUrl: './tab-usage-profiles.component.html',
  styleUrl: './tab-usage-profiles.component.scss',
})
export class TabUsageProfilesComponent {
  @Input({ required: true }) usageProfilesFormArray!: FormArray<FormGroup<DescriptionGroupFormGroup>>;

  public constructor(private _analyticsService: AnalyticsService) {
    this._analyticsService.sendPageView('tab-usage-profiles-component');
  }
}
