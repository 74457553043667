<ng-container [formGroup]="buildingDescriptionFormGroup">
  <div class="row tab-content">
    <h2>{{ title }}</h2>
  </div>

  <div class="row">
    <div class="col padding-right">
      <ng-container formArrayName="descriptions">
        @for (description of buildingDescriptions.controls; track description) {
        <ng-container [formGroup]="getDescriptionGroupAt($index)">
          <mat-form-field class="w-100-percent smaller-font">
            <mat-label>{{ getDescriptionTitleAt($index) }}</mat-label>
            <textarea matInput formControlName="description" rows="7" [attr.data-cy]="'input-tab-buildingDescription-description-' + $index"></textarea>
          </mat-form-field>
        </ng-container>
        }
      </ng-container>
    </div>
    <div class="col padding-right">
      <h3>Erforderliche Nachweise</h3>
      <ng-container formArrayName="attestations">
        @for (attestation of attestations.controls; track attestation) {
        <ng-container [formGroup]="getAttestationGroupAt($index)">
          <mat-checkbox formControlName="checked" color="primary">
            <span class="secondary-text">{{ getAttestationDescriptionAt($index) }}</span>
          </mat-checkbox>
        </ng-container>
        }
      </ng-container>
      <div class="attachments flexbox direction-column">
        <div class="label default-border-color primary-text flexbox small-gap align-center">
          <div class="attachments-title">Anhänge</div>
          <div [matTooltip]="'Aufgabe zu Gruppe hinzufügen'">
            <button
              mat-mini-fab
              color="accent"
              class="no-shadow smaller-button"
              (click)="onOpenAddAttachmentDialog()"
              data-cy="button-tab-buildingDescription-addAttestation"
            >
              <mat-icon>add</mat-icon>
            </button>
          </div>
        </div>
        <div class="row">
          @for(document of groupDocuments$ | async; track document){
          <eule-document-tile [documentId]="document.id" [attr.data-cy]="'document-tile-tab-buildingDescription-' + $index"></eule-document-tile>
          }
        </div>
      </div>
    </div>
  </div>
</ng-container>
