import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatMenu, MatMenuModule } from '@angular/material/menu';
import { Observable, catchError, from, of, take } from 'rxjs';
import {
  EditProfilePictureDialogComponent,
  EditProfilePictureDialogComponentConfig,
} from '../../../profile/components/edit-profile-picture-dialog/edit-profile-picture-dialog.component';
import { AuthService } from '../../services/auth-christian/auth.service';
import { StorageService } from '../../services/storage.service';

@Component({
  selector: 'eule-profile-picture',
  standalone: true,
  imports: [CommonModule, MatButtonModule, MatMenuModule, MatDialogModule],
  templateUrl: './profile-picture.component.html',
  styleUrl: './profile-picture.component.scss',
})
export class ProfilePictureComponent {
  @Input() matMenuTriggerFor!: MatMenu;
  @Input() isEditable: boolean = false;

  public profilePicture$: Observable<string | null> = of(null);

  public constructor(private _editProfilePictureDialog: MatDialog, private _storageService: StorageService, private _authService: AuthService) {
    this.profilePicture$ = from(this._storageService.downloadProfilePicture(this._authService.getAuth().currentUser!.uid)).pipe(
      take(1),
      catchError(() => {
        return of(null);
      })
    );

    this._storageService.fileChanges$.subscribe(() => (this.profilePicture$ = this._loadProfilePicture()));
    // this._storageService.fileChanges$.pipe(switchMap(() => this._loadProfilePicture())).subscribe();
  }

  public onClickProfilePicture() {
    if (!this.isEditable) return;

    const dialogRef = this._editProfilePictureDialog.open<EditProfilePictureDialogComponent, EditProfilePictureDialogComponentConfig, boolean | undefined>(
      EditProfilePictureDialogComponent,
      {
        width: '90vw',
        maxWidth: '90vw',
        height: '90vh',
        data: {},
      }
    );

    dialogRef.afterClosed().subscribe((result: boolean | undefined) => {
      if (result) {
        this.profilePicture$ = this._loadProfilePicture();
      }
    });
  }

  private _loadProfilePicture(): Observable<string | null> {
    return from(this._storageService.downloadProfilePicture(this._authService.getAuth().currentUser!.uid)).pipe(
      take(1),
      catchError(err => {
        console.error(err);
        return of(null);
      })
    );
  }
}
