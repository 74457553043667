<mat-dialog-content class="center">
  <input type="file" [accept]="allowedFileExtensions" #fileInput (change)="fileChangeEvent($event)" class="hidden" />

  <button mat-icon-button (click)="fileInput.click()" [ngClass]="croppedImage ? '' : 'large-icon-button'" data-cy="button-edit-profile-picture-upload">
    <mat-icon>upload</mat-icon>
  </button>

  @if(imageChangedEvent){
  <image-cropper
    [imageChangedEvent]="imageChangedEvent"
    [maintainAspectRatio]="true"
    [aspectRatio]="1 / 1"
    [roundCropper]="true"
    [cropperMinWidth]="200"
    [cropperMaxWidth]="1000"
    format="png"
    (imageCropped)="imageCropped($event)"
    (imageLoaded)="imageLoaded($event)"
    (cropperReady)="cropperReady()"
    (loadImageFailed)="loadImageFailed()"
  ></image-cropper>
  } @if(croppedImage){
  <h1>Vorschau</h1>
  <img [src]="croppedImage" alt="image" />}
</mat-dialog-content>

<mat-dialog-actions align="end" class="dialog-actions">
  <button mat-button [disabled]="!croppedImage || !croppedImageBlob" (click)="closeDialog(true)" data-cy="button-edit-profile-picture-save">Speichern</button>
  <button mat-button (click)="closeDialog(false)" cdkFocusInitial data-cy="button-edit-profile-picture-cancel">Abbrechen</button>
</mat-dialog-actions>
