<ng-container [formGroup]="leistungsPhasenFormGroup">
  <ng-template mat-tab-label>
    <span>Leistungsphasen</span>
    @if (leistungsPhasen.errors) {
    <mat-icon class="info" [matTooltip]="'Dieser Tab enthält falsch ausgefüllte Felder'"> info </mat-icon>
    }
  </ng-template>
  <div class="mat-elevation-z4">
    <div class="row tab-content">
      <div class="col padding-right">
        <ng-container formArrayName="leistungsPhasen">
          @for (phase of leistungsPhasen.controls; track phase) { @if ($index < 5) {
          <mat-form-field class="width-100 smaller-font">
            <mat-label>Beginn {{ staticLeistungsphasen[$index].title }}</mat-label>
            <input matInput formControlName="{{ $index }}" [matDatepicker]="dp" [attr.data-cy]="'input-tab-leistungsPhasen-' + $index" />
            <mat-datepicker-toggle matIconSuffix [for]="dp"></mat-datepicker-toggle>
            <mat-datepicker #dp></mat-datepicker>
            @if (phase?.hasError('lphDatebefore')) {
            <mat-error>Datum muss vor nachfolgenden und nach vorherigen Lph liegen</mat-error>
            }
          </mat-form-field>
          } }
        </ng-container>
      </div>
      <div class="col">
        <ng-container formArrayName="leistungsPhasen">
          @for (phase of leistungsPhasen.controls; track phase) { @if ($index >= 5) {
          <mat-form-field class="width-100 smaller-font">
            <mat-label>Beginn {{ staticLeistungsphasen[$index].title }}</mat-label>
            <input matInput formControlName="{{ $index }}" [matDatepicker]="dp" [attr.data-cy]="'input-tab-leistungsPhasen-' + $index" />
            <mat-datepicker-toggle matIconSuffix [for]="dp"></mat-datepicker-toggle>
            <mat-datepicker #dp></mat-datepicker>
            @if (phase?.hasError('lphDatebefore')) {
            <mat-error>Datum muss vor nachfolgenden und nach vorherigen Lph liegen</mat-error>
            }
          </mat-form-field>
          } }
        </ng-container>
        <mat-form-field class="width-100 smaller-font">
          <mat-label>Übergabedatum</mat-label>
          <input matInput formControlName="handoverDate" [matDatepicker]="dp" data-cy="input-tab-leistungsPhasen-handoverDate" />
          <mat-datepicker-toggle matIconSuffix [for]="dp"></mat-datepicker-toggle>
          <mat-datepicker #dp></mat-datepicker>
        </mat-form-field>
      </div>
    </div>
  </div>
</ng-container>
