import { Component, Input } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { MatDividerModule } from '@angular/material/divider';
import { DescriptionGroupFormGroup } from '../project-info-page/project-info-page.component';
import { ImagesDescriptionGroupComponent } from './images-description-group/images-description-group.component';
import { AnalyticsService } from '../../../../core/services/analytics/analytics.service';

@Component({
  selector: 'eule-tab-images',
  standalone: true,
  imports: [ImagesDescriptionGroupComponent, MatDividerModule],
  templateUrl: './tab-images.component.html',
  styleUrl: './tab-images.component.scss',
})
export class TabImagesComponent {
  @Input({ required: true }) totalImagesDescriptionFormGroup!: FormGroup;

  get imagesDescriptionFormArray() {
    return this.totalImagesDescriptionFormGroup.get('imagesDescriptionGroups') as FormArray<FormGroup<DescriptionGroupFormGroup>>;
  }

  public constructor(private _analyticsService: AnalyticsService) {
    this._analyticsService.sendPageView('tab-images-component');
  }
}
