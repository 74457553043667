<div id="profile-page" class="flexbox direction-column smaller-gap">
  <div id="user-info-header" class="dark-background">
    <div id="title-content">
      <eule-profile-picture [isEditable]="true"></eule-profile-picture>
      <p class="light-text">
        <b>{{ authUser?.displayName || authUser?.email }}</b>
      </p>
      <p class="light-text">{{ (_userService.euleUser$ | async)?.title || 'Projekt Administrator' }}</p>
    </div>
  </div>
  <mat-card class="custom-card">
    <mat-card-content>
      <mat-tab-group>
        <mat-tab id="profile-info-tab" data-cy="tab-profile-page-profile-info">
          <ng-template mat-tab-label> Persönliche Informationen</ng-template>
          <div class="tab-content">
            <eule-profile-information></eule-profile-information>
          </div>
        </mat-tab>

        <mat-tab id="security-tab" data-cy="tab-profile-page-security">
          <ng-template mat-tab-label> Sicherheit</ng-template>
          <div class="tab-content">
            <eule-profile-security></eule-profile-security>
          </div>
        </mat-tab>
      </mat-tab-group>
    </mat-card-content>
  </mat-card>
</div>
