import { Injectable } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { BuildingAreaBreakdown } from '@eeule/eeule-shared/src/types';

interface TotalForm {
  bgf: FormControl<number | null>;
  bri: FormControl<number | null>;
  nrf: FormControl<number | null>;
  nuf: FormControl<number | null>;
  tf: FormControl<number | null>;
  vf: FormControl<number | null>;
  kgf: FormControl<number | null>;
  adgnb: FormControl<number | null>;
  nfVehicle: FormControl<number | null>;
  vfHallCorridor: FormControl<number | null>;
  percentage: FormControl<number | null>;
}
@Injectable({
  providedIn: 'root',
})
export class BuildingParametersService {
  private _form!: FormGroup;
  private _totalForm: FormGroup | undefined;

  public get form() {
    return this._form;
  }
  public set form(value) {
    this._form = value;
    // this.calculateAllUsageProfiles();
    this.calculateTotalUsageProfiles();
  }
  public get totalForm() {
    return this._totalForm;
  }
  public set totalForm(value) {
    this._totalForm = value;
  }

  get usageProfiles(): FormArray {
    return this._form.get('usageProfiles') as FormArray;
  }

  public constructor(private _formBuilder: FormBuilder) {
    this.initTotalForm();
  }

  initTotalForm() {
    this._totalForm = this._formBuilder.group<TotalForm>({
      bgf: this._formBuilder.control({ value: 0, disabled: true }), // Combined Value of kgf + nrf
      bri: this._formBuilder.control({ value: 0, disabled: true }),
      nrf: this._formBuilder.control({ value: 0, disabled: true }), // Combined Value of nuf + tf + vf
      nuf: this._formBuilder.control({ value: 0, disabled: true }),
      tf: this._formBuilder.control({ value: 0, disabled: true }),
      vf: this._formBuilder.control({ value: 0, disabled: true }),
      kgf: this._formBuilder.control({ value: 0, disabled: true }),
      adgnb: this._formBuilder.control({ value: 0, disabled: true }), // Combined Value of nuf - nfVehicle + vfHallCorridor
      nfVehicle: this._formBuilder.control({ value: 0, disabled: true }),
      vfHallCorridor: this._formBuilder.control({ value: 0, disabled: true }),
      percentage: this._formBuilder.control({ value: 100, disabled: true }),
    });
  }

  getUsageProfile(profileIndex: number): FormControl {
    return this.usageProfiles.at(profileIndex) as FormControl;
  }
  getFloorsInProfile(profileIndex: number): FormArray {
    return this.getUsageProfile(profileIndex)?.get('floors') as FormArray;
  }

  calculateAllUsageProfiles() {
    for (let index = 0; index < this.usageProfiles.value.length; index++) {
      this.calculateUsageProfile(index);
    }
  }

  calculateUsageProfile(profileIndex: number) {
    const _sumOfFloors = this._sumUpFloorsInProfile(profileIndex);
    this._setUsageProfileValues(profileIndex, _sumOfFloors);
    this.calculateTotalUsageProfiles();
  }

  calculateTotalUsageProfiles() {
    const _sumOfTotalUsageProfiles = this._sumUpUsageProfiles();
    this._setTotalUsageProfilesValues(_sumOfTotalUsageProfiles);
    this.setPercentages();
  }

  private _sumUpFloorsInProfile(profileIndex: number): BuildingAreaBreakdown {
    const _floors = this.getFloorsInProfile(profileIndex);
    const _buildingAreaBreakdownSum: BuildingAreaBreakdown = {
      bgf: 0, // bgf is combined Value of kgf + nrf
      bri: 0,
      kgf: 0,
      nrf: 0, // nrf is combined Value of nuf + tf + vf
      nuf: 0,
      tf: 0,
      vf: 0,
      adgnb: 0, // adgnb is combined Value of nuf - nfVehicle + vfHallCorridor
      nfVehicle: 0,
      vfHallCorridor: 0,
    };
    for (const control of _floors.controls) {
      for (const key of Object.keys(_buildingAreaBreakdownSum) as (keyof BuildingAreaBreakdown)[]) {
        const _controlValue = +control.get(key)?.value || 0; // FIXME: here is string to number conversion happening (+'string'), the control value should be type number already
        _buildingAreaBreakdownSum[key] += _controlValue;
      }
    }

    return _buildingAreaBreakdownSum;
  }

  private _setUsageProfileValues(profileIndex: number, values: BuildingAreaBreakdown) {
    for (const key of Object.keys(values) as (keyof BuildingAreaBreakdown)[]) {
      this.getUsageProfile(profileIndex).get(key)?.setValue(values[key], { emitEvent: false });
    }
  }

  private _sumUpUsageProfiles(): BuildingAreaBreakdown {
    const _buildingAreaBreakdownSum: BuildingAreaBreakdown = {
      bgf: 0,
      bri: 0,
      kgf: 0,
      nrf: 0,
      nuf: 0,
      tf: 0,
      vf: 0,
      adgnb: 0,
      nfVehicle: 0,
      vfHallCorridor: 0,
    };

    for (const control of this.usageProfiles.controls) {
      for (const key of Object.keys(_buildingAreaBreakdownSum) as (keyof BuildingAreaBreakdown)[]) {
        const _controlValue = +control.get(key)?.value || 0; // FIXME: here is string to number conversion happening (+'string'), the control value should be type number already
        _buildingAreaBreakdownSum[key] += _controlValue;
      }
    }

    return _buildingAreaBreakdownSum;
  }

  private _setTotalUsageProfilesValues(values: BuildingAreaBreakdown) {
    for (const key of Object.keys(values) as (keyof BuildingAreaBreakdown)[]) {
      this.totalForm?.get(key)?.setValue(values[key], { emitEvent: false });
    }
  }

  private setPercentages() {
    const _totalAdgnb = this.totalForm?.get('adgnb')?.value;
    if (_totalAdgnb === 0) {
      return;
    }
    for (let index = 0; index < this.usageProfiles.value.length; index++) {
      const _profileAdgnb = this.getUsageProfile(index).get('adgnb')?.value || 0;
      const _profilePercentage = this.getUsageProfile(index).get('percentage');
      _profilePercentage?.setValue(Math.round((_profileAdgnb / _totalAdgnb) * 100 * 100) / 100);
    }
  }
}
