import { Component, Input } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { MatDividerModule } from '@angular/material/divider';
import { AnalyticsService } from '../../../../core/services/analytics/analytics.service';
import { DescriptionGroupFormGroup, TotalDescriptionGroupsFormGroup } from '../project-info-page/project-info-page.component';
import { BuildingDescriptionGroupComponent } from './building-description-group/building-description-group.component';

@Component({
  selector: 'eule-tab-building-description',
  standalone: true,
  imports: [BuildingDescriptionGroupComponent, MatDividerModule],
  templateUrl: './tab-building-description.component.html',
  styleUrl: './tab-building-description.component.scss',
})
export class TabBuildingDescriptionComponent {
  @Input({ required: true }) totalBuildingDescriptionFormGroup!: FormGroup<TotalDescriptionGroupsFormGroup>;

  get buildingDescriptionFormArray() {
    return this.totalBuildingDescriptionFormGroup.get('buildingDescriptionGroups') as FormArray<FormGroup<DescriptionGroupFormGroup>>;
  }
  
  public constructor(private _analyticsService: AnalyticsService) {
    this._analyticsService.sendPageView('tab-building-description-component');
  }
}
