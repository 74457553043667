@if (isLoading$ | async) {
<div class="loading-overlay">
  <mat-spinner [diameter]="100" />
</div>
} @else {
<eule-general-title [title]="'Benutzerliste'">
  <div class="flexbox smaller-gap align-center">
    <button mat-raised-button color="primary" (click)="openAddUserDialog()" data-cy="button-users-list-page-add">
      <mat-icon>add</mat-icon>
      Benutzer
    </button>

    <div class="v-divider"></div>

    <eule-search-bar id="search-field" [(search)]="filterValue" (searchChange)="applyFilter($event)"></eule-search-bar>
  </div>
</eule-general-title>

<eule-users-list [dataSource]="dataSource!"></eule-users-list>
}
