<h2 mat-dialog-title>Neues Projekt</h2>
@if(isLoading$ | async){
<mat-spinner [diameter]="50"></mat-spinner>
} @else {
<mat-dialog-content [formGroup]="dataForm" class="mat-typography" id="new-project-dialog-content">
  <div class="row">
    <mat-form-field class="w-100-percent no-background smaller-font">
      <mat-label>Bauphase</mat-label>
      <mat-select
        formControlName="lifeCyclePhase"
        (selectionChange)="filterDgnbSystems(lifeCyclePhaseSelect.value)"
        #lifeCyclePhaseSelect
        class="w-100-percent no-background smaller-font"
        data-cy="select-lifeCyclePhase"
      >
        @for (phase of lifeCyclePhaseEnum | keyvalue; track phase) {
        <mat-option [value]="phase.key">{{ phase.value }}</mat-option>
        }
      </mat-select>
    </mat-form-field>
  </div>
  <!-- TODO: Systems are lifeCyclePhase specific and this dropdown options should depend on prevous contruction phase selection  -->
  @if(lifeCyclePhaseSelect.value){
  <div class="row">
    <mat-form-field class="w-100-percent no-background smaller-font">
      <mat-label>Zertifizierungssystem</mat-label>
      <mat-select formControlName="dgnbSystem" #dgnbSystem (selectionChange)="dgnbSystemChange($event)" data-cy="select-system">
        @for (system of filteredDgnbSystems; track system) {
        <mat-option [value]="system.id" [attr.data-system]="system">{{ system.name }} {{ system.certificationVersion }}</mat-option>
        }
      </mat-select>
    </mat-form-field>
  </div>
  }
  <div class="row">
    <mat-form-field class="w-100-percent no-background smaller-font">
      <mat-label>Name</mat-label>
      <input matInput formControlName="name" data-cy="input-name" />
    </mat-form-field>
  </div>
  <div class="row">
    <mat-form-field class="w-100-percent no-background smaller-font">
      <mat-label>Nummer</mat-label>
      <input matInput formControlName="number" data-cy="input-number" />
    </mat-form-field>
  </div>
  <div class="row">
    <mat-slide-toggle formControlName="bgfBigger5000" (change)="toggleBgfBigger5000($event)" data-cy="toggle-bgfBigger5000"></mat-slide-toggle>
    <mat-label>Bruttogeschossfläche > 5.000 m2</mat-label>
  </div>
  <div class="row">
    <mat-slide-toggle formControlName="withDeconstruction" (change)="toggleCircularEconomy($event)" data-cy="toggle-withDeconstruction"></mat-slide-toggle>
    <mat-label>Mit Rückbau</mat-label>
  </div>
  <div class="row">
    <ng-container [formGroup]="getUsageProfileFormGroup(0)">
      <mat-form-field class="w-100-percent no-background smaller-font">
        <mat-label>Hauptnutzung</mat-label>
        <mat-select formControlName="usageProfile" data-cy="select-main-usageProfile">
          @for (profileType of filteredUsageProfiles$ | async; track profileType) {
          <mat-option [value]="profileType.id">{{ usageProfileEnum[profileType.name] }}</mat-option>
          }
        </mat-select>
      </mat-form-field>
    </ng-container>
  </div>
  <div class="row">
    <mat-slide-toggle
      formControlName="hasSubordinateUsage"
      #subordinateUsageProfiles
      (change)="toggleSubordinateUsageProfiles($event)"
      data-cy="toggle-hasSubordinateUsage"
    ></mat-slide-toggle>
    <mat-label>Untergeord. Nutzung</mat-label>
  </div>
  @for(profiles of subordinateUsageProfilesArray.controls; track profiles){
  <div class="row">
    <ng-container [formGroup]="getSubordinateUsageProfileFormGroup($index)">
      <mat-form-field class="w-100-percent no-background smaller-font">
        <mat-label>Unterg. Nutzung</mat-label>
        <mat-select formControlName="usageProfile" data-cy="select-subordinate-usageProfile">
          @for (profileType of filteredUsageProfiles$ | async; track profileType) {
          <mat-option [value]="profileType.id">{{ usageProfileEnum[profileType.name] }}</mat-option>
          }
        </mat-select>
      </mat-form-field>
    </ng-container>
  </div>
  } @if(subordinateUsageProfiles.checked) {
  <button mat-raised-button color="primary" (click)="addSubordinateUsageProfile()">Untergeord. Nutzung hinzufügen</button>
  }

  <div class="row">
    <mat-slide-toggle
      formControlName="hasMixedUsageProfiles"
      #mixedUsageProfiles
      (change)="toggleMixedUsageProfiles($event)"
      data-cy="toggle-hasMixedUsageProfiles"
    ></mat-slide-toggle>
    <mat-label>Mischnutzung</mat-label>
  </div>
  @for(profiles of sideUsageProfilesArray.controls; track profiles){
  <div class="row">
    <ng-container [formGroup]="getSideUsageProfileFormGroup($index)">
      <mat-form-field class="w-100-percent no-background smaller-font">
        <mat-label>Nebennutzung</mat-label>
        <mat-select formControlName="usageProfile" data-cy="select-side-usageProfile">
          @for (profileType of filteredUsageProfiles$ | async; track profileType) {
          <mat-option [value]="profileType.id">{{ usageProfileEnum[profileType.name] }}</mat-option>
          }
        </mat-select>
      </mat-form-field>
    </ng-container>
  </div>
  } @if(mixedUsageProfiles.checked) {
  <button mat-raised-button color="primary" (click)="addMixedUsageProfile()">Nebennutzung hinzufügen</button>
  }
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close [mat-dialog-close]="undefined" data-cy="button-cancel">Abbrechen</button>
  <button mat-raised-button color="primary" [mat-dialog-close]="dataForm.value" [disabled]="dataForm.invalid" data-cy="button-create">Erstellen</button>
</mat-dialog-actions>
}
