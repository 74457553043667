import { Component } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { ProjectListComponent } from '../components/project-list/project-list.component';
import { InvoiceSettingsComponent } from '../components/invoice-settings/invoice-settings.component';
import { EnterprisePlanComponent } from '../components/enterprise-plan/enterprise-plan.component';
import { AnalyticsService } from '../../core/services/analytics/analytics.service';

@Component({
  selector: 'eule-subscripton-page',
  standalone: true,
  imports: [MatIconModule, MatTabsModule, ProjectListComponent, InvoiceSettingsComponent, EnterprisePlanComponent],
  templateUrl: './subscripton-page.component.html',
  styleUrl: './subscripton-page.component.scss',
})
export class SubscriptonPageComponent {
  public constructor(private _analyticsService: AnalyticsService) {
    this._analyticsService.sendPageView('subscription-page');
  }
}
