import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialog } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatTooltip } from '@angular/material/tooltip';
import { Router } from '@angular/router';
import { DgnbSystem } from '@eeule/eeule-shared/src/types';
import { MixedUsageProfile, Pagination, Project, ProjectData, User } from '@eeule/eeule-shared/src/types/index';
import {
  BehaviorSubject,
  EMPTY,
  Observable,
  catchError,
  combineLatest,
  delay,
  forkJoin,
  from,
  map,
  mergeMap,
  of,
  switchMap,
  take,
  takeUntil,
  tap,
  throwError,
} from 'rxjs';
import { getUsageProfileEnumValue } from '../../../util/enum.helper';
import { BaseComponent } from '../../core/components/base/base.component';
import { ConfirmDialogComponent, ConfirmDialogData } from '../../core/components/confirm-dialog/confirm-dialog.component';
import { GeneralTitleComponent } from '../../core/components/general-title/general-title.component';
import { SearchBarComponent } from '../../core/components/search-bar/search-bar.component';
import { TypeSafeMatCellDef } from '../../core/directives/TypeSafeMatCellDef';
import { AnalyticsService } from '../../core/services/analytics/analytics.service';
import { AuthService } from '../../core/services/auth-christian/auth.service';
import { IndicatorService } from '../../core/services/indicator.service';
import { ProjectService } from '../../core/services/project.service';
import { SnackbarService } from '../../core/services/snackbar.service';
import { UsageProfileService } from '../../core/services/usage-profiles/usage-profile.service';
import { ProjectUserDisplay, UserService } from '../../core/services/user.service';
import { UsageProfileEnum } from '../../enums/UsageProfile.enum';
import { NewProjectDialogComponent } from '../components/new-project-dialog/new-project-dialog.component';
import { NewProjectHelperService } from '../services/new-project-helper.service';

export type ProjectTableData = Project & { usageProfileKeys: UsageProfileEnum[] };

@Component({
  selector: 'eule-project-list',
  standalone: true,
  imports: [
    CommonModule,
    GeneralTitleComponent,
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatSortModule,
    MatTableModule,
    SearchBarComponent,
    MatCardModule,
    MatTooltip,
    TypeSafeMatCellDef,
  ],
  templateUrl: './project-list.component.html',
  styleUrl: './project-list.component.scss',
})
export class ProjectListComponent extends BaseComponent implements OnInit, AfterViewInit {
  public displayedColumns: string[] = ['name', 'number', 'systemType', 'projectOwner', 'date', 'addressCity', 'usageProfiles', 'actions'];
  public dataSource: MatTableDataSource<ProjectTableData> = new MatTableDataSource<ProjectTableData>();
  public filterValue: string = '';
  public deletingInProgress$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public isLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private _refresh$: BehaviorSubject<null> = new BehaviorSubject(null);

  protected readonly getUsageProfileEnumValue = getUsageProfileEnumValue;

  private _authUserId: string | undefined = this._authService.getAuth().currentUser?.uid;

  @ViewChild(MatPaginator) paginator!: MatPaginator | null;
  @ViewChild(MatSort) sort!: MatSort | null;

  constructor(
    private _analyticsService: AnalyticsService,
    private _router: Router,
    private _newProjectDialog: MatDialog,
    public projectService: ProjectService,
    public userService: UserService,
    private _indicatorService: IndicatorService,
    private _usageProfilesService: UsageProfileService,
    private _snackbarService: SnackbarService,
    private analyticsService: AnalyticsService,
    private _newProjectHelperService: NewProjectHelperService,
    private _authService: AuthService,
    private _confirmDialog: MatDialog
  ) {
    super();
    this._analyticsService.sendPageView('project-list-page');
  }

  ngOnInit() {
    this._loadProjects();
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  public onChangePageSize(pageSize: Pagination | number) {
    this.userService.updateUser(this.userService.euleUser$.value!.id, { pagination: pageSize as Pagination });
  }

  public newProject(): void {
    this.analyticsService.sendEvent('button_click', { label: 'project-list_newProject' });
    const dialogRef = this._newProjectDialog.open<NewProjectDialogComponent, Project, Project | undefined>(NewProjectDialogComponent, {
      width: '40vw',
      maxWidth: '40vw',
      height: '80vh',
    });

    dialogRef
      .afterClosed()
      .pipe(
        switchMap((newProjectDialogResult: Project | undefined) => {
          if (!newProjectDialogResult) {
            return EMPTY; // normaler geplanter Abbruch, wenn der Dialog abgebrochen bzw. ohne Ergebnis geschlossen wurde
          }
          if (!newProjectDialogResult?.dgnbSystem) {
            throw new Error('Project DGNB System not set');
          }
          if (!newProjectDialogResult?.id) {
            throw new Error('Project ID not set');
          }
          if (newProjectDialogResult?.bgfBigger5000 === newProjectDialogResult?.bgfSmaller5000) {
            throw new Error('bgfBigger5000 and bgfSmaller5000 have the same value. One must be true, the other false');
          }
          if (newProjectDialogResult?.withDeconstruction === newProjectDialogResult?.withoutDeconstruction) {
            throw new Error('withDeconstruction and withoutDeconstruction have the same value. One must be true, the other false');
          }

          if (newProjectDialogResult && this._authUserId) {
            this.isLoading$.next(true);
            const projectOwnerId: string = crypto.randomUUID();
            const _project: Project = {
              ...newProjectDialogResult,
              buildingDescriptionGroups: this._newProjectHelperService.getBuildingDescriptionGroups(),
              usageProfiles: newProjectDialogResult.usageProfiles.map((profile: MixedUsageProfile) =>
                this._newProjectHelperService.getUsageProfileWithDescriptionGroup(profile)
              ),
              certificationDescriptionGroups: this._newProjectHelperService.getCertificationDescriptionGroups(),
              imagesDescriptionGroups: this._newProjectHelperService.getImagesDescriptionGroups(),
              projectOwner: projectOwnerId,
              leistungsPhasen: new Array(9).fill(null),
              qng: false,
              euTaxonomy: false,
            };

            return combineLatest([of(_project), this.projectService.createProject(_project)]);
          }
          return EMPTY;
        }),
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        switchMap(([proj, _]: [Project, void]) => {
          return combineLatest([of(proj), this.projectService.addUserToProject(proj.id, this._authUserId!, proj.projectOwner)]);
        }),
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        switchMap(([proj, _]: [Project, void]) => combineLatest([of(proj), this.userService.addProjectToUser(proj.id, this._authUserId!)])),
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        switchMap(([proj, _]: [Project, void]) =>
          of(
            this._indicatorService.cloud_copyDgnbSystemToProjectPreCheck(
              `dgnbSystems/${proj.dgnbSystem}`,
              `projects/${proj.id}/preCheckScenarios/${crypto.randomUUID()}`
            )
          )
        ),
        delay(1000), // FIXME: ist das Delay noch notwendig, nachdem die nested Subscriptions aufgelöst und in eine chronologische Observablekette überführt wurde?
        tap(() => {
          this._loadProjects();
          this.isLoading$.next(false);
        }),
        catchError(error => {
          this.isLoading$.next(false);
          throw new Error('Error:', error);
        })
      )
      .subscribe();
  }

  applyFilter(_filterValue: string) {
    this.filterValue = _filterValue;
    if (this.dataSource) {
      this.dataSource.filter = _filterValue.trim().toLowerCase();

      if (this.dataSource.paginator) {
        this.dataSource.paginator.firstPage();
      } else {
        new Error('No DataSource for Table');
      }
    }
  }

  public clickProjekt(project: ProjectData) {
    this._router.navigate(['/intern/project', project.id]).catch(error => console.error(error));
  }

  public onDelete(projectId: string, event: Event) {
    event.stopPropagation();
    this._confirmDialog
      .open<ConfirmDialogComponent, ConfirmDialogData, boolean>(ConfirmDialogComponent, {
        width: '360px',
        data: { dynamicContent: 'Projekt löschen' },
      })
      .afterClosed()
      .pipe(
        take(1),
        switchMap(takeAction => {
          if (!takeAction) return EMPTY;
          this.deletingInProgress$.next(true);
          return from(this.projectService.cloudFunctionSoftDeleteProject(projectId));
        }),
        tap(() => {
          this.deletingInProgress$.next(false);
          this._refresh$.next(null);
        }),
        catchError((err) => {
          this.deletingInProgress$.next(false);
          console.error('Error deleting Project', err);
          return err;
        })
      )
      .subscribe();
  }

  private _loadProjects() {
    if (this._authUserId) {
      this.isLoading$.next(true);

      const userProjects$: Observable<Project[]> = this._refresh$.pipe(
        switchMap(() => this.userService.getUser(this._authUserId!)),
        switchMap((user: User) => {
          if (user.projectIds?.length) {
            return this.projectService.getProjectsByIds(user.projectIds);
          } else {
            return of([]);
          }
        }),
        // read project User Data for displaying in List
        mergeMap((projects: Project[]) => {
          const arr = projects.map(proj =>
            this.projectService.getProjectUserDisplay(proj.id, proj.projectOwner!).pipe(
              map((user: ProjectUserDisplay) => {
                if (user?.firstName && user?.lastName) {
                  return { ...proj, projectOwner: `${user.firstName} ${user.lastName}` };
                }
                if (user?.email) {
                  return { ...proj, projectOwner: user.email };
                }
                return { ...proj, projectOwner: '---' }; // only for display purposes
              })
            )
          );

          if (arr.length) {
            return forkJoin(arr);
          } else {
            return of([]);
          }
        }),
        takeUntil(this.stop$)
      );

      combineLatest([
        userProjects$.pipe(
          mergeMap(projects => {
            if (!projects?.length) return of([]);
            const arr: Observable<DgnbSystem>[] = projects
              .filter(project => project.dgnbSystem?.length)
              .map(proj => {
                return this._indicatorService.getDgnbSystem(proj.dgnbSystem);
              });
            return forkJoin(arr).pipe(
              map(dgnbSystems => {
                return projects.map(project => {
                  return {
                    ...project,
                    resolvedDgnbSystem: dgnbSystems.find(dgnbSystem => dgnbSystem.id === project.dgnbSystem),
                  };
                });
              })
            );
          })
        ),
        this._usageProfilesService.getUsageProfiles(),
      ])
        .pipe(
          catchError(error => {
            this._snackbarService.showErrorMessage('Beim Initialisieren der Daten ist ein Fehler aufgetreten.');
            return throwError(() => error);
          }),
          takeUntil(this.stop$)
        )
        .subscribe(([projects, usageProfiles]) => {
          this.dataSource.data =
            projects.map(project => {
              return {
                ...project,
                usageProfileKeys:
                  usageProfiles.data
                    ?.filter(up => {
                      return project.usageProfiles.map(pup => pup.usageProfile).some(pup => pup === up.id);
                    })
                    .map(up => up.name as UsageProfileEnum) || [],
              };
            }) || [];
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          this.isLoading$.next(false);
        });
    } else throw new Error('Auth User not found');
  }
}
